import React from 'react';
import Div from '../Div';
import './logolist.scss';
const partnerLogos = [
  {
    src: '/images/brands/brand-l-01.png',
    alt: 'Partner',
  },
  {
    src: '/images/brands/brand-l-02.png',
    alt: 'Partner',
  },
  {
    src: '/images/brands/brand-l-03.png',
    alt: 'Partner',
  },
  {
    src: '/images/brands/brand-l-04.png',
    alt: 'Partner',
  },
  {
    src: '/images/brands/brand-l-06.png',
    alt: 'Partner',
  }
  // {
  //   src: '/images/brands/brand-l-07.png',
  //   alt: 'Partner',
  // }
  // ,
  // {
  //   src: '/images/brands/brand-l-08.png',
  //   alt: 'Partner',
  // }
  // ,
  // {
  //   src: '/images/brands/brand-l-09.png',
  //   alt: 'Partner',
  // }
];

export default function LogoList() {
  return (
    <Div className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index) => (
        <div className="cs-partner_logo" key={index}>
          <img src={partnerLogo.src} alt={partnerLogo.alt} />
        </div>
      ))}
    </Div>
  );
}
