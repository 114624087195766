import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import FunFact from "../FunFact";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import BrandSlide from "../logo-slider";

const funfaceData = [
  {
    title: "Global Happy Clients",
    factNumber: "100+",
  },
  {
    title: "Project Completed",
    factNumber: "500+",
  },
  {
    title: "Team Members",
    factNumber: "30",
  },
  {
    title: "ROI Generated",
    factNumber: "1M+",
  },
];

export default function AboutPage() {
  pageTitle("About");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Your trusted Agency for your Business"
              subtitle="About Loop"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                Tired of the same old marketing strategies that don’t deliver
                results? With Loop’s expert marketing consultants onboard, that
                is not a problem. For over 6 years, we have been helping
                businesses in the IT, blockchain, real estate, beauty,
                automotive, and gaming industry craft marketing strategies that
                generate 2x more business.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_img_11.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          title="Our fun fact"
          subtitle="Believe in the power of creativity. Expand your business significantly by leveraging your knowledge in the realm of the digital industry and create."
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Highly experienced people with us"
              subtitle="Why Choose Us"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
                "We're strategists, storytellers, brand builders and digital
                thought leaders. We're the Full-Service Integrated marketing
                agency you've been looking for. Believe in the power of
                creativity. Expand your business significantly by leveraging
                your knowledge in the realm of the digital industry and create
                remarkable marketing experiences that cut through the noise."
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0">
                We are a 360° marketing agency with 20+ experts in event
                management, content marketing, social media marketing, email
                marketing, performance marketing, SEO, web design & development,
                and videography & photography.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}
            {/* Start Team Section */}
            <Spacing lg="145" md="80" />
      {/* <Div className="container">
        <SectionHeading
          title="Our team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        
      </Div> */}
      {/* End Team Section */}
        {/* Start LogoList Section */}
        <Spacing lg="145" md="80" />
        <Div className="container">
        <SectionHeading
                title='Our Clients' 
                subtitle='Loop Clients'
              />
       <BrandSlide/>
             </Div>



      

      {/* Start CTA Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss make <br />something <b>Great</b> together"
          btnText="Book Demo"
          btnLink="/contact"TEAM
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
