// src/components/LanguageSwitcher.jsx

import React, { useEffect } from 'react';

const LanguageSwitcher = () => {
  useEffect(() => {
    const loadGoogleTranslate = () => {
      const script = document.createElement('script');
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        { pageLanguage: 'en', includedLanguages: 'ar' },
        'google_translate_element'
      );
    };

    loadGoogleTranslate();
  }, []);

  return (
    <div className="mt-4 rounded-lg shadow-sm">
      <div id="google_translate_element"></div>
    </div>
  );
};

export default LanguageSwitcher;
