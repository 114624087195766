import React, { useState } from 'react';
import Slider from 'react-slick';
import { Icon } from '@iconify/react';
import Testimonial from '../Testimonial';
import Div from '../Div';
import Spacing from '../Spacing';
const testimonialData = [
  {
    testimonialThumb: '/images/testimonial_1.png',
    testimonialText:
      'From the moment we started, it was evident that Loop took the time to understand our unique needs. They crafted content that not only resonated with our audience but also drove engagement and conversions.',
    avatarName: 'Rookie Ninja',
    avatarDesignation: 'CEO',
    ratings: '4',
  },
  {
    testimonialThumb: '/images/testimonial_3.png',
    testimonialText:
      'Efficient and Effective. Thats loop! They revamped our website , and ourconversion rate shot up by 50%.Plus, their data-driven insights keep usahead of the competition.',
    avatarName: 'Assuretek',
    avatarDesignation: 'CEO',
    ratings: '5',
  },
  {
    testimonialThumb: '/images/testimonial_2.png',
    testimonialText:
      'Working with Loop was a game-changer. Their strategic approach and creative ideas breathed new life into our brand. Our sales increased by 40% withinjust three months.',
    avatarName: 'Justlife',
    avatarDesignation: 'CEO',
    ratings: '4.5',
  },
  {
    testimonialThumb: '/images/testimonial_4.png',
    testimonialText:
      'Outstanding partner! This agencys innovative content marketing ideas breathed life into our brand. Our blog engagement soared, making us a thought leader in our industry.',
    avatarName: 'Tabadull',
    avatarDesignation: 'CEO',
    ratings: '3.5',
  },
];

export default function TestimonialSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );
  return (
    <>
      <Div className="cs-gradient_bg_1 cs-shape_wrap_3 cs-parallax">
        <Spacing lg="130" md="80" />
        <Div className="cs-shape_3 cs-to_up">
          <img src="/images/shape_1.svg" alt="Shape" />
        </Div>
        <Div className="container">
          <Div className="cs-testimonial_slider">
            <Div className="cs-testimonial_slider_left">
              <Slider
                asNavFor={nav1}
                ref={slider2 => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="0px"
                arrows={false}
              >
                {testimonialData.map((item, index) => (
                  <Div className="slider-nav_item" key={index}>
                    <Div className="cs-rotate_img">
                      <Div className="cs-rotate_img_in">
                        <img src={item.testimonialThumb} alt="Thumb" />
                      </Div>
                    </Div>
                  </Div>
                ))}
              </Slider>
            </Div>
            <Div className="cs-testimonial_slider_right">
              <Slider
                asNavFor={nav2}
                ref={slider1 => setNav1(slider1)}
                prevArrow={<SlickArrowLeft />}
                nextArrow={<SlickArrowRight />}
                className="cs-arrow_style1"
              >
                {testimonialData.map((item, index) => (
                  <Div key={index}>
                    <Testimonial
                      testimonialText={item.testimonialText}
                      avatarName={item.avatarName}
                      avatarDesignation={item.avatarDesignation}
                      ratings={item.ratings}
                    />
                  </Div>
                ))}
              </Slider>
            </Div>
          </Div>
        </Div>
        <Spacing lg="130" md="80" />
      </Div>
    </>
  );
}
