import React from 'react';
import Hero12 from '../Hero/Hero12';
const heroSocialLinks = [
  {
    name: 'Instagram',
    links: 'https://www.instagram.com/loopmea/',
  },
  {
    name: 'Tiktok',
    links: '/',
  },
  {
    name: 'Linkedin',
    links: 'https://www.linkedin.com/company/loopmea/',
  }
];

const showcaseData = [
  {
    introTitle: '',
    title: '',
    videoUrl: 'video/loop.mp4',
    href: '#',
  },
  {
    introTitle: 'Video Production | Gitex Dubai 2023',
    title: 'Al Jammaz Technologies',
    videoUrl: 'video/intro-video-2.mp4',
    href: '#',
  },
  {
    introTitle: 'Video Production',
    title: 'Seven Seas',
    videoUrl: 'video/intro-video-5.mp4',
    href: '#',
  },
  {
    introTitle: 'Video Production',
    title: 'Asustor Partnership Event',
    videoUrl: 'video/intro-video-3.mp4',
    href: '#',
  },
  {
    introTitle: '',
    title: '',
    videoUrl: 'video/intro-video-4.mp4',
    href: '',
  },
  ,
  {
    introTitle: 'Video Production',
    title: 'ICL Fincorp',
    videoUrl: 'video/intro-video-6.mp4',
    href: '#',
  },
 
];

export default function Home() {
  return (
    <>
      <Hero12
        heroSocialLinks={heroSocialLinks}
        socialLinksHeading="Follow Us"
        showcaseData={showcaseData}
      />
    </>
  );
}
